var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"divvy-form auth-wrapper auth-v2"},[(_vm.loading)?_c('Loader'):_vm._e(),_c('b-row',{staticClass:"divvy-form__container auth-inner m-0"},[_c('img-logo'),_c('b-col',{staticClass:"align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"6","md":"6","lg":"12"}},[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"divvy-form__form mt-2"},[_c('h1',{staticClass:"divvy-form__title"},[_vm._v("Faça seu cadastro")]),_c('b-form-group',{attrs:{"label":"Tipo","label-for":"register-tipo"}},[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-tipo","options":_vm.options},on:{"change":_vm.changeLabel},model:{value:(_vm.registro.typePerson),callback:function ($$v) {_vm.$set(_vm.registro, "typePerson", $$v)},expression:"registro.typePerson"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.labelNome,"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.registro.name),callback:function ($$v) {_vm.$set(_vm.registro, "name", $$v)},expression:"registro.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},on:{"blur":_vm.verifyRegisterEmail},model:{value:(_vm.registro.emailAddress),callback:function ($$v) {_vm.$set(_vm.registro, "emailAddress", $$v)},expression:"registro.emailAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Celular","label-for":"register-celular"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####']),expression:"['(##) #####-####']"}],attrs:{"id":"register-celular","name":"register-celular","state":errors.length > 0 ? false : null,"placeholder":"(21) 99999-9999"},model:{value:(_vm.registro.phoneNumber),callback:function ($$v) {_vm.$set(_vm.registro, "phoneNumber", $$v)},expression:"registro.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.isExternal === false)?_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"pass",attrs:{"label-for":"Senha","label":"Senha"}},[_c('validation-provider',{attrs:{"name":"Senha","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"Senha","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"Senha","placeholder":"············","min":"8"},model:{value:(_vm.registro.password),callback:function ($$v) {_vm.$set(_vm.registro, "password", $$v)},expression:"registro.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1196356229)})],1)],1),_c('b-col',[_c('b-card-text',[_c('b-form-group',{attrs:{"label-for":"register-repeat-password","label":"Repetir Senha"}},[_c('validation-provider',{attrs:{"name":"Confirmar senha","rules":"required|confirmed:Senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"repeat-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"repeat-password","placeholder":"Confirmar senha"},model:{value:(_vm.registro.repeatPassword),callback:function ($$v) {_vm.$set(_vm.registro, "repeatPassword", $$v)},expression:"registro.repeatPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2327353126)})],1)],1)],1)],1):_vm._e(),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.checked},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Abrir Conta ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{staticClass:"back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Voltar ")])],1)],1)],1),_c('background-card')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }