<template>
  <div class="divvy-form auth-wrapper auth-v2">
    <Loader v-if="loading"/>
    <b-row class="divvy-form__container auth-inner m-0">
      <img-logo />

      <b-col lg="6" class="align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="6" md="6" lg="12" class="px-xl-2 mx-auto">
          <validation-observer ref="registerForm">
            <b-form class="divvy-form__form mt-2">
              <h1 class="divvy-form__title">Faça seu cadastro</h1>
              <b-form-group label="Tipo" label-for="register-tipo">
                <validation-provider
                  #default="{ errors }"
                  name="Tipo"
                  rules="required">
                  <b-form-select
                    id="register-tipo"
                    v-model="registro.typePerson"
                    :options="options"
                    @change="changeLabel" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- name -->
              <b-form-group :label="labelNome" label-for="register-name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required">
                  <b-form-input
                    id="register-name"
                    v-model="registro.name"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email">
                  <b-form-input
                    id="register-email"
                    v-model="registro.emailAddress"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    @blur="verifyRegisterEmail" 
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Celular -->
              <b-form-group label="Celular" label-for="register-celular">
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="required">
                  <b-form-input
                    id="register-celular"
                    v-model="registro.phoneNumber"
                    v-mask="['(##) #####-####']"
                    name="register-celular"
                    :state="errors.length > 0 ? false : null"
                    placeholder="(21) 99999-9999" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row v-if="isExternal === false">
                <b-col>
                  <b-form-group class="pass" label-for="Senha" label="Senha">
                    <validation-provider
                      #default="{ errors }"
                      name="Senha"
                      rules="required|password">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input
                          id="Senha"
                          v-model.lazy="registro.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="Senha"
                          placeholder="············"
                          min="8" />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-card-text>
                    <b-form-group
                      label-for="register-repeat-password"
                      label="Repetir Senha">
                      <validation-provider
                        #default="{ errors }"
                        name="Confirmar senha"
                        rules="required|confirmed:Senha">
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input
                            id="repeat-password"
                            v-model="registro.repeatPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="repeat-password"
                            placeholder="Confirmar senha" />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-button
                class="mt-3"
                variant="primary"
                block
                type="submit"
                :disabled="checked"
                @click.prevent="validationForm">
                Abrir Conta
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link class="back" @click="$router.go(-1)"> Voltar </b-link>
          </p>
        </b-col>
      </b-col>

      <background-card />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormSelect,
  BLink,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import BackgroundCard from './template/BackgroundCard.vue'
import imgLogo from './template/ImgLogo.vue'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    BRow,
    // BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    Loader,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BackgroundCard,
    imgLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      isExternal: false,
      registro: {
        name: '',
        surname: '',
        userName: 'username',
        emailAddress: '',
        password: '',
        repeatPassword: '',
        phoneNumber: '',
        typePerson: 'F',
        termsOfUse: false,
      },
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      imgLogo: require('@/assets/images/logo/logo.png'),
      imgRegister: require('@/assets/images/pages/login/img-login-crop.png'),
      // validation
      required,
      email,
      error: '',
      compass: false,
      checked: false, // true
      options: [
        { value: 'F', text: 'Pessoa Física' },
        { value: 'J', text: 'Pessoa Juridica' },
      ],
      labelNome: 'Nome Completo',

      path: '',
      warning: '',
    }
  },
  async mounted(){
    const item = localStorage.getItem('externalLogin')
    if(Boolean(item)){
      await this.getUserDataExternal()
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    surname() {
      // eslint-disable-next-line no-return-assign,vue/no-side-effects-in-computed-properties
      return (this.registro.surname = 'L')
    },
  },

  methods: {  
    async getUserDataExternal() {
      this.isExternal = true
      const model = {
        authProvider: localStorage.getItem('authProvider'),
        providerKey: localStorage.getItem('providerKey'),
        providerAccessCode: localStorage.getItem('providerAccessCode'),
        singleSignIn: false,
      }
      const response =  await this.$http.post(
        '/api/TokenAuth/GetExternalUserInfo',
        model,
        { validateStatus: () => true },
      )
      let result = response.data.result
      this.registro.name = result.name + ' ' + result.surname
      this.registro.emailAddress = result.emailAddress
    },

    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.register()
        }
      })
    },
    async register() {
      try {
        this.loading = true
        const n = {
          name: this.registro.name,
          surname: this.getSurname(this.registro.name),
          userName: this.registro.emailAddress,
          emailAddress: this.registro.emailAddress,
          password: this.registro.password,
          phoneNumber: this.registro.phoneNumber,
          typePerson: this.registro.typePerson,
          termsOfUse: true,
          authProvider: localStorage.getItem('authProvider'),
          providerKey: localStorage.getItem('providerKey'),
          providerAccessCode: localStorage.getItem('providerAccessCode'),
        }
        let endpoint = '/api/services/app/Account/Register'
        if(this.isExternal) {
          endpoint = '/api/services/app/Account/RegisterExternalUser'
          n.password = '12345678910'
        }
        const response = await this.$http
          .post(endpoint, n, {
            withCredentials: false,
            timeout: 30000, 
            validateStatus: () => true,
          })

        if (response.data.error) {
          this.error = response.data.error.message
          this.loading = false
          this.msgError()
          return
        }
        this.error = ''
        this.loading = false
        this.msgSuccess()
        setTimeout(() => {
          this.$router.push({ name: 'registered' })
        }, 2700)
      } catch (error) {
        this.error = error.message
        this.loading = false
        this.msgError()
      }
    },
    check() {
      this.checked = !this.checked
    },
    isDisabled() {
      return false
    },
    changeLabel() {
      if (this.registro.typePerson === 'F') this.labelNome = 'Nome Completo'
      if (this.registro.typePerson === 'J') this.labelNome = 'Razão Social'
    },

    async verifyRegisterEmail(e) {
      const response = await this.$http.get(
        `/api/services/app/BrokerPublicService/FindBrokerExistingEmail?search=${e.target.value}`,
      )
      if (response.data.result.statusCode === 200) {
        this.path = 'login'
        this.warning = 'Caro usuário, já possuimos uma conta com seu email, favor realizar o login ou tente recuperar sua senha.'
        this.msgWarning()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Sua conta foi registrada com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // // error
    // msgError() {
    //   this.$swal({
    //     icon: 'error',
    //     title: 'Error ao registrar conta',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //     },
    //     buttonsStyling: false,
    //     html: `${this.error}
    //         <br> <br> <br>
    //         <button type="button" variant="success" class="btn btn-success btn-yes swl-cstm-btn-yes-sbmt-rqst">Login</button>
    //         <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
    //     showCancelButton: false,
    //     showConfirmButton: false,
    //     onBeforeOpen: () => {
    //       const yes = document.querySelector('.btn-yes')
    //       const no = document.querySelector('.btn-no')

    //       yes.addEventListener('click', () => {
    //         this.$swal.close()
    //         this.$router.push({ name: 'login' })
    //       })

    //       no.addEventListener('click', () => {
    //         this.$swal.close()
    //       })
    //     },
    //   })
    // },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao efetuar o cadastro',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="primary" class="btn btn-primary btn-yes swl-cstm-btn-yes-sbmt-rqst">Login</button>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          const yes = document.querySelector('.btn-yes')
          const no = document.querySelector('.btn-no')

          yes.addEventListener('click', () => {
            this.$swal.close()
            this.$router.push({ name: this.path })
          })

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },
    getSurname(str) {
      const textReplace = ' '
      const res = str.substring(str.indexOf(textReplace) + textReplace.length)
      return res
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import './sass/variables.scss';
@import './sass/divvy-form.scss';
a.back {
  color: #000;
  font-weight: 500;
}
</style>
